.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.match-badge {
	margin-left: 1px;
	margin-right: 1px;
	margin-bottom: 1px;
	padding: 4px;
	width: 20px;
	height: 20px;
	display: inline-block;
	font-weight: 600;
	font-size: 0.8em;
	line-height: 1em;
	border-radius: 4px;
	text-align: center;
}

.match-badge.win {
	background-color: #2096f3;
	color: white;
}

.match-badge.loss {
	background-color: #40464d;
	color: white;
}
