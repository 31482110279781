.bracket-compact .fullscreen-enabled .scroll-container {
    /* background: linear-gradient(rgb(255, 255, 255), rgb(240, 242, 245)); */
}

.bracket-compact .fullscreen {
    /* background: linear-gradient(rgb(255, 255, 255), rgb(240, 242, 245)); */
}

.bracket-compact .full-screenable-node {
    height: 100%;
}

.bracket-compact .match-link {
    color: #333;
    padding: 0;
    height: 20px;
}

.bracket-compact .player.winner.active-path {
    color: #fff;
    background: #566d84 !important;
}

.bracket-compact.dark .player.winner.active-path {
    color: #fff;
    background: #1891ff !important;
}

.bracket-compact .player.active-path .player-score {
    color: #fff;
    background: #566d84 !important;
}

.bracket-compact.dark .player.active-path .player-score {
    color: #fff;
    background: #1891ff !important;
}

.bracket-compact .active-path {
    color: #fff;
    background: #566d84 !important;
}

.bracket-compact.dark .active-path {
    color: #fff;
    background: #1891ff !important;
}

.bracket-compact.dark .set-total {
    background: #135592 !important;
}

.bracket-compact.light .set-total {
    background: #c5e9ff !important;
}

.mobilegpu {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.bracket-compact .draw {
    display: flex;
}

.bracket-compact .a-side {
    display: flex;
    width: 100%;
}

.bracket-compact .b-side {
    display: flex;
    /* width: 100%; */
    /* order: -1; */
}

.bracket-compact .round.money-round {
    background-color: rgba(230, 255, 230, 0.35);
    /* border-right: 1px solid lightgreen !important; */
    /* background-image: url("../../../../assets/img/money-round-bg.png"); */
    /* background-repeat: repeat; */
}

/* #bracket-container {
	display: flex;
}

#bracket-container .bracket-content {
	flex: 1;
} */

.ant-modal-wrap {
    left: 250px !important;
}

/* .b-side .round:first-child .match {
	padding-left: 20px;
} */

.bracket-compact .b-side .round:first-child .match:before {
    border: none !important;
}

.bracket-compact .a-side .round:last-child .match {
    padding-right: 20px;
}

.bracket-compact .a-side .round:last-child .match:after {
    border: none !important;
}

#bracket-container .ant-drawer {
    position: fixed;
    top: 166px;
}

#scores .ant-drawer {
    position: fixed;
    left: 250px;
}

#scores .ant-drawer-content-wrapper {
    width: calc(100% - 250px);
}
/* 
#table-scores {
	position: relative;
	overflow: auto;
	width: 100%;
	height: 100%;
} */

/* 
#bracket-container .ant-drawer-content-wrapper {
	width: 100%;
}

#bracket-container .details-panel {
	flex: 1;
} */

/* .losers .match-spacer {
	border-left: 1px solid #aaa;
}

.winners .match-spacer {
	border-right: 1px solid #aaa;
} */

/* .losers .round:nth-child(n + 1) {
	order: -1;
} */

/* .round .spacer {
	flex-grow: 0.5;
} */

/* .losers .round:nth-child(n + 2) .spacer {
	flex-grow: 0.5;
} */

.bracket-compact .connector {
    /* flex-shrink: 0; */
    /* flex: 1; */
    align-items: center;
    /* height: 100%; */
    display: flex;
    /* margin-top: 66px; */
    padding: 32px 0px;
}

.bracket-compact .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.3);
}

.bracket-compact .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.3);
}
.bracket-compact .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.6);
}

.bracket-compact .connector > div {
    display: inline-block;
    vertical-align: middle;
}

.bracket-compact .connector .merger {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    width: 2rem;
    display: inline-block;
    vertical-align: top;
    border-top: 1px solid #c0c0c8;
    border-bottom: 1px solid #c0c0c8;
    min-height: 66px;
}

.bracket-compact .connector.left .merger {
    border-left: 1px solid #c0c0c8;
}

.bracket-compact .connector.right .merger {
    border-right: 1px solid #c0c0c8;
}
/* 
.connector .merger:before {
	border-right-width: thin;
	border-top-width: thin;

	content: '';
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 50%;
	border: 0 solid;
	border-color: #c0c0c8;
}

.connector .merger:after {
	border-right-width: thin;
	border-bottom-width: thin;

	content: '';
	display: block;
	box-sizing: border-box;
	width: 100%;
	height: 50%;
	border: 0 solid;
	border-color: #c0c0c8;
} */

.bracket-compact .connector .line {
    border-bottom: thin solid #c0c0c8;
    /* height: 50%; */
    box-sizing: border-box;
    width: 2rem;
    display: inline-block;
    vertical-align: top;
    flex: 1;
    align-items: center;
}

.bracket-compact .rounds {
    display: flex;
}

table.hoverable tbody tr:hover {
    background-color: #fafafa;
}

.bracket-compact .round {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0px 0 40px 0;
    min-width: 320px;
    /* min-width: 280px; */
    /* transition: all 150ms ease-in-out; */
    border-right: 1px solid transparent;
    /* border-right: 1px dashed #ccc; */
    /* width: 100%; */
}

/* .bracket-compact .round:hover {
	background-color: #d4f0ff;
} */

.bracket-compact .round:last-child {
    /* width: 100%; */
}

/* .round:hover {
	background-color: rgba(0, 0, 0, 0.025);
} */

.ant-btn.approve-match-btn {
    background-color: #009ba2;
    border-color: #009ba2;
}

.bracket-compact .round-header {
    font-size: 12px;
    text-align: center;
    padding: 12px;
    text-transform: uppercase;
    /* color: #777; */
    /* background-color: #fff; */
    /* background-color: #fff !important; */
    /* min-width: 300px; */
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    /* border-right: 1px solid #ededed; */
    margin-bottom: 20px;
    margin-top: 0px;
    /* min-height: 62px; */
    min-height: 80px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); */
}

/* .round > div:nth-child(n + 2) {
	margin: 0 10px;
} */

/* .round .spacer:nth-child(n + 2) {
	flex-grow: 1;
	text-align: center;
} */

.bracket-compact .round .match-spacer {
    flex-grow: 1;
    padding: 0.5em 0em;
    text-align: center;
    min-height: 20px;
    /* min-height: 40px; */
    /* min-height: 66px; */
    align-items: center;
    display: flex;
    justify-content: center;
}

.bracket-compact .round.compact .match-spacer {
    padding: 0;
}
.round .match-spacer.red {
    /* background-color: red; */
}

.round .match-spacer.blue {
    /* background-color: blue; */
}

.round .match-spacer.green {
    /* background-color: green; */
}

.bracket-compact .b-side .round .match-spacer.odd {
    border: none !important;
}

.bracket-compact .b-side .match.top.odd:before,
.bracket-compact .b-side .match.bottom.odd:before {
    height: 2px !important;
    top: 50% !important;
}

.bracket-compact .b-side .match.top.even:after {
    height: 50%;
    top: 50%;
    border-right-style: solid;
    /* border-left-style: solid; */
    right: -10px;
}

.bracket-compact .b-side .match.bottom.even:after {
    height: 50%;
    bottom: 50%;
    border-right-style: solid;
    /* border-left-style: solid; */
    right: -10px;
}

/* .b-side .round .match-spacer {
	margin-left: -1px;
} */

.bracket-compact .a-side .round .match-spacer.draw-only {
    /* border-left: 2px solid #888888; */
    border: none !important;
}

.bracket-compact .draw .round .match-spacer {
    /* border-left: 2px solid #888888; */
    border-right: 2px solid #888888;
}

.bracket-compact .a-side .round .match-spacer {
    border-right: 2px solid #888888;
}

.bracket-compact .b-side .round .match-spacer {
    border-right: 2px solid #888888;
    /* border-left: 2px solid #c0c0c8; */
}

.bracket-compact .no-connector {
    border: none !important;
}

.bracket-compact .round .spacer {
    flex-grow: 0.5;
    text-align: center;
}

.bracket-compact .matches {
    /* display: flex; */
    /* flex: 1; */
    /* flex-direction: column;
	left: -1px;
	position: relative;
	width: 100%; */
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: space-between;
}

.bracket-compact .match-group {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    flex: 1;
    flex-grow: 1;
}

.bracket-compact .match-group > div {
    /* display: inline-block; */
    /* vertical-align: middle; */
}

/* .match:first-child {
	margin-bottom: 10rem;
} */

.bracket-compact .match {
    /* margin-top: -23px; */
    margin: 0 10px;
    position: relative;

    /* height: 70px; */
}

.dark-mode .ant-collapse-content {
    background-color: #08121f !important;
}

.bracket-compact .match-winner {
    position: relative;
    right: -20px;
    padding: 10px 15px;
    border-radius: 8px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ff6163;
    white-space: nowrap;
}

.bracket-compact .match-winner:before {
    content: '';
    background-color: #888888;
    border-width: 2px;
    position: absolute;
    height: 2px;
    width: 16px;
    left: -20px;
    top: calc(50% - 2px);
}

.bracket-compact.dark .match-winner {
    /* background-color: #2f4d6b; */
    background-color: #06101b;
}

.bracket-compact.dark .match-winner > span {
    color: #fff;
}

.bracket-compact.light .match-winner {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.65);
}

.bracket-compact.light .match-winner > span {
    color: #aeb4bb;
}

.bracket-compact .bracket-surface {
    position: relative;
}

.bracket-compact .match.bottom {
    /* margin-top: -5px; */
    /* margin-top: -9px; */
}

.bracket-compact .a-side .round:nth-last-child(2) .match:after {
    border-right-style: none !important;
    border-top-style: none !important;
    border-bottom-style: none !important;
}

.bracket-compact .b-side .round:nth-last-child(2) .match:after {
    border-right-style: none !important;
    border-top-style: none !important;
    border-bottom-style: none !important;
}

.bracket-compact .a-side .match:not(.top):not(.bottom):before {
    border-left-style: solid;
    border-top-style: solid;
    height: 2px;
    top: 50%;
}

.bracket-compact .a-side .match:not(.top):not(.bottom):after {
    border-right-style: solid;
    border-top-style: solid;
    height: 2px;
    top: 50%;
}

.bracket-compact .b-side .match:not(.top):not(.bottom):before {
    border-left-style: solid;
    border-top-style: solid;
    height: 2px;
    top: 50%;
}

.bracket-compact .b-side .match:not(.top):not(.bottom):after {
    border-right-style: solid;
    border-top-style: solid;
    height: 2px;
    top: 50%;
}

.bracket-compact .match:before {
    content: '';
    border-color: #888888;
    border-width: 2px;
    position: absolute;
    height: 2px;
    width: 10px;
    left: -12px;
    /* top: calc(50% + 10px); */
}

.bracket-compact .draw .match:before {
    left: -10px;
}

.bracket-compact .a-side .match:before {
    left: -13px;
}

.bracket-compact .b-side .match:before {
    left: -10px;
}

.bracket-compact .match.top.no-connectors:before {
    height: auto;
}

.bracket-compact .match.bottom.no-connectors:before {
    height: auto !important;
    top: 50% !important;
}

.bracket-compact .match.top.no-right-connector:after {
    height: auto;
}

.bracket-compact .match.bottom.no-right-connector:after {
    height: auto;
}

.bracket-compact .draw .match.top:before,
.bracket-compact .draw .match.bottom:before {
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: none;
}

.bracket-compact .match.top:before {
    border-left-style: solid;
    border-top-style: solid;
    height: 50%;
    top: 50%;
}

.bracket-compact .match.bottom:before {
    border-left-style: solid;
    border-bottom-style: solid;
    height: 50%;
    top: 0px;
    /* top: calc(50% - 32px); */
}

.bracket-compact .match:after {
    content: '';
    border-color: #888888;
    border-width: 2px;
    position: absolute;
    display: block;
    width: 10px;
    right: -10px;
}

.bracket-compact .draw .match:before {
    right: -12px;
}

.bracket-compact .a-side .match:after {
    right: -10px;
}

.bracket-compact .b-side .match:after {
    right: -13px;
}

.bracket-compact .match.top:after {
    border-right-style: solid;
    border-top-style: solid;
    height: 50%;
    top: 50%;
}

.bracket-compact .match.bottom:after {
    border-right-style: solid;
    border-bottom-style: solid;
    height: 50%;
    top: 0px;
}

.bracket-compact .b-side .match.top:before {
    border-left-style: none;
    border-top-style: solid;
    height: 50%;
    top: 50%;
}

.bracket-compact .b-side .match.top:after {
    border-right-style: none;
    border-top-style: solid;
    height: 50%;
    top: 50%;
}

.bracket-compact .b-side .match.bottom:after {
    border-right-style: none;
    border-bottom-style: solid;
    height: 50%;
    top: 0px;
}

.bracket-compact .b-side .match.bottom:before {
    border-left-style: none;
    border-bottom-style: solid;
    height: 50%;
    top: 0px;
}

/* .match:last-child:after {
	transform: translateY(-100%);
}

.match:before {
	content: '';
	position: absolute;
	background-color: #fff;
	right: 0;
	top: 50%;
	transform: translateX(100%);
	width: 25px;
	height: 2px;
}

.match:after {
	content: '';
	position: absolute;
	background-color: #fff;
	right: -25px;
	height: calc(50% + 22px);
	width: 2px;
	top: 50%;
} */

.bracket-compact .time {
    font-size: 12px;
    padding-left: 5px;
    padding-bottom: 5px;
    color: #aaa;
}

.bracket-compact .live .match-data {
    background-color: #f5212d;
}

.bracket-compact .live .match-number {
    color: #f5212d;
}

.bracket-compact .match.completed .match-data {
    /* opacity: 0.7; */
}

.bracket-compact .match.completed:hover .match-data {
    opacity: 1;
}

.bracket-compact .match.completed .match-number {
    background-color: #d3dce4;
    color: #000;
}

.bracket-compact.dark .match.completed .match-number {
    background-color: #172b40;
    color: #fff;
}

.bracket-compact.dark .match.pending .match-number {
    /* background-color: #ff6e19; */
    /* background-color: #b74604; */
    background-color: #056c72;
    color: #fff;
}

.bracket-compact .match.pending .match-number {
    /* background-color: #ff6e19; */
    /* background-color: #ff8217; */
    background-color: #078890;
    color: #fff;
}

.bracket-compact .match.in_progress .match-number {
    background-color: #198fff;
    color: #fff;
}

.bracket-compact.dark .match.in_progress .match-number {
    background-color: #172b40;
    color: #fff;
}

.bracket-compact .match.streaming .match-number {
    background-color: #f5212d !important;
    color: #fff;
}

.bracket-compact .match.not_started > div {
    /* opacity: 0.8; */
}

.bracket-compact .match:hover > div {
    opacity: 1;
    /* transition: all 300ms cubic-bezier(0.29, 0.15, 0.07, 1.01); */
}

.bracket-compact .match.completed:hover {
    opacity: 1;
    /* transition: all 300ms cubic-bezier(0.29, 0.15, 0.07, 1.01); */
}

.bracket-compact .match.in_progress {
    opacity: 1;
}

.bracket-compact .match.in_progress.has_players .match-scores {
    background: linear-gradient(90deg, #d3e8ff, #fff);
    /* box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15); */
}

.bracket-compact.dark .match.in_progress.has_players .match-scores {
    background: linear-gradient(90deg, #0666b9, #304d6a);
    /* background: linear-gradient(90deg, #1792ff, #304d6a); */
    /* box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15); */
}

.bracket-compact .match.has_payout .match-data {
    border: 1px solid #7ec7a9;
}

.bracket-compact .match.has_payout .match-number {
    background-color: #c0e6d6;
}

.bracket-compact.dark .match.has_payout .match-number {
    background-color: #64ad8e;
    color: #000;
}

.bracket-compact .match.has_payout .match-data:hover {
    border: 1px solid #35b37f;
}

.bracket-compact.dark .ant-progress-inner {
    background-color: #2f4d6b;
}

.bracket-compact .match.completed .match-data {
    /* background-color: #1d212a; */
    /* box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15); */
}

.bracket-compact .match.assigned .match-data {
    /* box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15); */
}

.bracket-compact .match.not_started .player-score {
    /* background: #fff; */
}

.bracket-compact .match.bye .match-scores {
    opacity: 0.4;
}

.bracket-compact .match.not_started.has_players .match-scores {
    /* background: linear-gradient(90deg, #d3e8ff, #fff); */
}

.bracket-compact .match.assigned .match-scores {
    background: linear-gradient(90deg, #304d6a, #556c83);
}

.bracket-compact .match.assigned .match-scores {
    background: linear-gradient(90deg, #304d6a, #556c83);
}

.bracket-compact .completed .match-data {
    /* background-color: #1d212a; */
}

.bracket-compact .completed .match-number {
    color: white;
}

.bracket-compact .zoom-buttons-container {
    position: absolute;
    right: 10px;
    bottom: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    border: 1px solid rgb(238, 238, 238);
    padding: 10px;
}

.bracket-compact.dark .zoom-buttons-container {
    position: absolute;
    right: 10px;
    bottom: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    background-color: #000;
    border-radius: 10px;
    border: 1px solid #000;
    padding: 10px;
}

.bracket-compact .zoom-buttons {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 5px 15px rgba(0, 63, 255, 0.2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    cursor: pointer;
    touch-action: manipulation;
    height: 32px;
    width: 36px;
    user-select: none;
    font-size: 14px;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.bracket-compact .zoom-buttons:hover {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.bracket-compact .zoom-minus {
    margin-top: 10px;
}

.bracket-compact .match-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* display: none; */
    height: 16px;
}

.bracket-compact .match-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.05);
    /* border: 5px solid rgba(0, 0, 0, 0.05) !important; */
    /* background-color: #d3dce4; */
    /* background-color: #c9e5fd; */
    border-radius: 8px;
    overflow: hidden;
    /* transition: all 150ms ease-in-out; */
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
    border: 1px solid #d3dce4;
}

.bracket-compact.dark .match-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 5px solid rgba(255, 255, 255, 0.1) !important; */
    /* background-color: #d3dce4; */
    /* background-color: #c9e5fd; */
    border-radius: 8px;
    overflow: hidden;
    /* transition: all 150ms ease-in-out; */
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
    border: 1px solid #d3dce4;
}

.bracket-compact .match-data:hover {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    border: 1px solid #198fff !important;
}

.bracket-compact.dark .match-data:hover {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
    border: 1px solid #4da9ff !important;
}

.bracket-compact .winner-loser-from {
    color: #aeb4bb;
    padding-right: 50px;
}

/* .bracket-compact .player-name .ellipsis {
    padding-right: 20px;
} */

.bracket-compact .match-footer {
    margin: 2px 0 0 0;
    font-size: 12px;
    /* color: #aaa; */
    height: 16px;
    /* display: none; */
}

.bracket-compact .compact .match-footer {
    height: 0px;
    /* display: none; */
}

.bracket-compact .match-number {
    /* padding: 26px 5px; */
    padding: 10px 6px;
    /* min-width: 30px; */
    text-align: center;
    background-color: #d3dce4;
    border-radius: 8px 0px 0px 8px;
    color: #000;
}

.bracket-compact.dark .match-number {
    /* padding: 26px 5px; */
    padding: 10px 6px;
    /* min-width: 30px; */
    text-align: center;
    background-color: #172b40;
    border-radius: 8px 0px 0px 8px;
    color: #fff;
}

.bracket-compact .player-seed {
    /* background-color: #e4f2ff; */
    /* background-color: #fff; */
    background-color: rgba(255, 255, 255, 0);
    padding: 5px;
    min-width: 30px;
    text-align: center;
    /* border-right: 1px solid rgba(255, 255, 255, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.bracket-compact.dark .ant-btn-secondary {
    background-color: #000;
    color: #fff;
}

/* .bracket-surface {
	border: 1px solid #ccc;
	background-color: #fafafa;
} */

.bracket-compact .match-scores {
    flex: 1;
    background-color: #fff;
    min-width: 300px;
    /* background-color: #f5f6f8; */
}

.bracket-compact.dark .pending .match-scores {
    flex: 1;
    background-color: #056c72;
    /* background-color: #f5f6f8; */
}

.bracket-compact .pending .match-scores {
    flex: 1;
    background-color: #aceae5;
    /* background-color: #f5f6f8; */
}

.bracket-compact.dark .match-scores {
    flex: 1;
    background-color: #2f4d6b;
    /* background-color: #f5f6f8; */
}

.bracket-compact .player {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(237, 237, 237);
    cursor: pointer;

    /* transition: all 150ms cubic-bezier(0.29, 0.15, 0.07, 1.01); */
}

.bracket-compact .player-number {
    align-items: center;
    /* background-color: #fafafa; */
    border-right: 1px solid rgb(237, 237, 237);
    padding: 5px 10px 5px 10px;
    min-width: 35px;
    text-align: center;
}

.bracket-compact .player-name {
    align-items: center;
    flex: 1;
    text-align: left;
    /* padding: 5px 10px 5px 10px; */
    display: flex;
    /* white-space: nowrap; */
    font-weight: bold;
    height: 34px;
    white-space: nowrap;
}

.bracket-compact .player-name .flag {
    margin-right: 10px;
}

.bracket-compact .player-score {
    align-items: center;
    font-weight: 600;
    padding: 8px 10px 5px 10px;
    /* border-left: 1px solid rgba(0, 0, 0, 0.5); */
    min-width: 25px;
    text-align: center;
    font-size: 1.1em;
    /* border-left: 1px solid rgb(237, 237, 237); */
    /* background-color: #fff; */
}

.bracket-compact .player-score + .player-score {
    padding: 8px 0px 5px 0px;
}

.bracket-compact .player-score + .player-score {
    border-left: 1px solid #ededed;
}

.bracket-compact.dark .player-score + .player-score {
    border-left: 1px solid rgba(0, 0, 0, 0.5);
}

.bracket-compact .player-score:last-child {
    margin-right: 2px;
}

.bracket-compact .player.bottom {
    border-bottom: 0px;
}

.bracket-compact .player.winner:hover {
    /* background-color: #f3e9b7; */
}

.bracket-compact .player.loser:hover {
    /* background-color: #eee; */
}

.bracket-compact .match.streaming .player {
    background: linear-gradient(90deg, #ffe1e3, #fff);
}

.bracket-compact.dark .match.streaming .player {
    background: linear-gradient(90deg, #8a191f, #304d6a);
}

.bracket-compact .match.streaming .player.winning {
    background: linear-gradient(90deg, #ffc0c4, #fff);
}

.bracket-compact.dark .match.streaming .player.winning {
    background: linear-gradient(90deg, #f5212d, #304d6a);
}

.bracket-compact .match.streaming .player.losing {
    background: linear-gradient(90deg, #ffe1e3, #fff);
}

.bracket-compact.dark .match.streaming .player.losing {
    background: linear-gradient(90deg, #8a191f, #304d6a);
}

.bracket-compact .match.streaming .player-score {
    /* background-color: #fff; */
}

.bracket-compact .player.tied {
    /* background: linear-gradient(90deg, #d3e8ff, #fff); */
}

.bracket-compact .player.tied .player-score {
    /* background-color: #fff; */
}

.ant-progress.dark .ant-progress-inner {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.bracket-compact .player.losing {
    background: linear-gradient(90deg, #d3e8ff, #fff);
}

.bracket-compact.dark .player.losing {
    background: linear-gradient(90deg, #0866b8, #2f4e6c);
}

.bracket-compact .pending .player.losing {
    /* background: linear-gradient(90deg, #fff2db, #fff); */
    background: linear-gradient(90deg, #aceae5, #fff);
}

.bracket-compact.dark .pending .player.losing {
    /* background: linear-gradient(90deg, #b74604, #304d6a); */
    background: linear-gradient(90deg, #006e73, #304d6a);
}

.bracket-compact .player.losing .player-score {
    /* background-color: #fff; */
}

.bracket-compact .player.loser {
    opacity: 0.6;
    /* background: linear-gradient(90deg, #d5dae6, #fff); */
}

.bracket-compact .player.loser .player-score {
    /* background-color: #fff; */
}

.bracket-compact.dark .player.loser .player-score {
    background-color: #2f4d6b;
}

.bracket-compact .player.winner {
    /* background-color: #fff3bd; */
    background: linear-gradient(-90deg, #fff9e1, #fff);
    background: #fff9e1;
    /* background: linear-gradient(90deg, #b7bfd0, #fff); */
    /* color: #fff; */
}

.bracket-compact.dark .player.winner {
    /* background: #3d566f; */
    background: #22598e;
}

/* .player.winner .player-seed {
	background-color: #a7d6ff;
} */

.bracket-compact .player.winning {
    background: linear-gradient(90deg, #a2ceff, #fff);
}

.bracket-compact.dark .player.winning {
    background: linear-gradient(90deg, #2e97fe, #304d6a);
}

.bracket-compact .pending .player.winning {
    /* background: linear-gradient(90deg, #fde3aa, #fff); */
    background: linear-gradient(90deg, #63d2c9, #fff);
}

.bracket-compact.dark .pending .player.winning {
    /* background: linear-gradient(90deg, #ff6e19, #304d6a); */
    background: linear-gradient(90deg, #009ba2, #304d6a);
}

.bracket-compact .player.winning .player-score {
    /* margin-left: 1px; */
    /* background-color: #fff; */
    /* color: red; */
}

.bracket-compact .player.winner .player-score {
    /* background-color: orange; */
    background-color: #fff4bf;
    font-weight: 700;
    /* color: #fff; */
}

.bracket-compact.dark .player.winner .player-score {
    /* background-color: orange; */
    background-color: #216bb1;
    /* background-color: #607a94; */
    font-weight: 700;
    /* color: #fff; */
}

/* .match {
	font-size: 1.3em;
	display: flex;
	height: 30px;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	white-space: nowrap;
	min-width: 200px;
} */

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
.animate-flicker {
    -webkit-animation: flickerAnimation 1s 3;
    -moz-animation: flickerAnimation 1s 3;
    -o-animation: flickerAnimation 1s 3;
    animation: flickerAnimation 1s 3;
}

.dark-mode .bracket-compact .match-scores.blinking {
    animation: blinkingPlayerLight 1.2s infinite;
}

.dark-mode .bracket-compact .player.blinking {
    animation: blinkingPlayerLight 1.2s infinite;
}

.light-mode .bracket-compact .match-scores.blinking {
    animation: blinkingPlayerDark 1.2s infinite;
}

.light-mode .bracket-compact .player.blinking {
    animation: blinkingPlayerDark 1.2s infinite;
}

.bracket-compact .player.blinking .player-score {
    background-color: #1890ff !important;
}

.bracket-compact .player.active-path.blinking .player-score {
    background-color: #1890ff !important;
}

@keyframes blinkingPlayerLight {
    0% {
        color: #fff;
        background-color: #1890ff;
        border-color: #1890ff;
    }
    49% {
        color: #fff;
        background-color: #1890ff;
        border-color: #1890ff;
    }
    60% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    99% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    100% {
        color: #fff;
        background-color: #1890ff;
        border-color: #1890ff;
    }
}

@keyframes blinkingPlayerDark {
    0% {
        color: #000;
        background-color: #1890ff;
        border-color: #1890ff;
    }
    49% {
        color: #000;
        background-color: #1890ff;
        border-color: #1890ff;
    }
    60% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    99% {
        color: transparent;
        background-color: none;
        border-color: none;
    }
    100% {
        color: #000;
        background-color: #1890ff;
        border-color: #1890ff;
    }
}
