#loader {
	background-color: rgba(0, 0, 0, 0.15);
	min-height: 100%;
	min-width: 100%;
	top: 0px;
	left: 0px;
	position: fixed;
	z-index: 999;
	display: -webkit-box;
	display: -moz-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-moz-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-pack: center;
	-moz-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}

#loader.dark {
	background-color: #081629;
}

#loader .spinner-container {
	position: absolute;
	left: 0px;
	top: 0px;
	display: block;
	width: 100%;
	height: 100%;
}

#loader .loading-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

#loader .message {
	color: #fff;
	position: absolute;
	margin-top: 150px;
}

#loader .spinner-container .spinner-loading {
	top: 50%;
	left: 50%;
}

#loader .logo {
	display: inline-block;
	width: 20%;
	max-width: 90px;
	position: relative;
}

#loader .loading-indicator {
	position: relative;
}

#loader .loader.dots div {
	width: 10px;
	height: 10px;
	position: absolute;
	top: 50%;
	-webkit-border-radius: 50%;
	-webkit-background-clip: padding-box;
	-moz-border-radius: 50%;
	-moz-background-clip: padding;
	border-radius: 50%;
	background-clip: padding-box;
}

#loader .loader.dots .round-trip {
	background-color: #9277e5;
	animation: move 1.8s infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

#loader .loader.dots .open-jaw {
	background-color: #9277e5;
	animation: move 1.8s 150ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

#loader .loader.dots .one-way {
	background-color: #9277e5;
	animation: move 1.8s 300ms infinite cubic-bezier(0.2, 0.64, 0.81, 0.23);
}

@keyframes move {
	0% {
		left: 0%;
		zoom: 1;
		filter: alpha(opacity=25);
		-webkit-opacity: 0.25;
		-moz-opacity: 0.25;
		opacity: 0.25;
	}

	50% {
		zoom: 1;
		filter: alpha(opacity=100);
		-webkit-opacity: 1;
		-moz-opacity: 1;
		opacity: 1;
	}

	100% {
		left: 100%;
		zoom: 1;
		filter: alpha(opacity=25);
		-webkit-opacity: 0.25;
		-moz-opacity: 0.25;
		opacity: 0.25;
	}
}

#loader .spinner {
	-webkit-animation: rotator 1.4s linear infinite;
	-moz-animation: rotator 1.4s linear infinite;
	-o-animation: rotator 1.4s linear infinite;
	animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

#loader .path {
	stroke-dasharray: 187;
	stroke-dashoffset: 0;
	transform-origin: center;
	stroke: #fff;
	-webkit-animation: dash 1.4s ease-in-out infinite;
	-moz-animation: dash 1.4s ease-in-out infinite;
	-o-animation: dash 1.4s ease-in-out infinite;
	animation: dash 1.4s ease-in-out infinite;
}

@keyframes dash {
	0% {
		stroke-dashoffset: 187;
	}

	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
}
