.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    width: 100%;
    height: 300px;
}

.pin {
    display: flex;
    align-items: center;
}

.pin-icon {
    font-size: 2em;
    color: #000;
}

.pin-text {
    font-size: 1em;
    margin-left: 3px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: 700;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 500px;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 200px;
    }

    .pin-icon {
        font-size: 2em;
    }
}
